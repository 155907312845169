body { margin: 0; }

#bridge {
	display: block;
	margin: 0 auto;
    background: none;
	width: 100%;
	max-width: 900px;
	height: auto;
    opacity: 0.9;
  cursor:  crosshair;
  cursor: url(https://gsaas-getmok.s3.ap-south-1.amazonaws.com/default/file-1659945391968.png) 20 20, crosshair;
}
#bridgeContainer { 
  text-align: center;
  font-family: Avenir, sans-serif;
}
#bridgeContainer figcaption {   
  margin-top: 2rem; 
}